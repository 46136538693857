class APIModel {
  //for server
  // static URL = "https://backend.easy-card.co/";
  // static HOST = "https://backend.easy-card.co/api/";
  // static WEBSITE = "https://easy-card.co/";
  // static PORTAL="https://portal.easy-card.co/"

  // for local
  static URL = "http://backend.myeasycard.co/";
  static HOST = "http://backend.myeasycard.co/api/";
  static WEBSITE = "http://myeasycard.co/";
  static PORTAL = "http://portal.myeasycard.co/";
}

export default APIModel;
